body {
  color: #2b303b;
  background: #fcd34d;
  margin: 0;
  padding: 3em 1em 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-size: 1em;
  line-height: 1.5;
}

h1, h2 {
  margin: 0 0 .5em;
  font-weight: 900;
  line-height: 1;
}

footer {
  font-size: .8rem;
}

footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.contact {
  margin: 2em 0;
}

.contact p {
  margin: 0;
}

@media (min-width: 40em) {
  body {
    font-size: 3em;
  }
}

@media (min-width: 80em) {
  main {
    max-width: 65vw;
  }
}

/*# sourceMappingURL=index.ac331df5.css.map */
