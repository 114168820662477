body{
  margin: 0;
  padding: 3em 1em 0;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  background: #FCD34D;
  color: hsl(221, 16%, 20%);
  font-size: 1em;
  line-height: 1.5;
}
h1, h2{
  line-height: 1;
  margin: 0 0 0.5em;
  font-weight: 900;
}
footer{
  font-size: 0.8rem;
}
footer ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
.contact{
  margin: 2em 0;
}
.contact p{
  margin: 0;
}

@media (min-width: 40em) {
  body{
    font-size: 3em;
  }
}

@media (min-width: 80em) {
  main {
    max-width: 65vw;
  }
}
